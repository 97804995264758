.App {
  text-align: center;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Roboto', sans-serif; /* Modern sans-serif font */
}

.App-header {
  background-color: #282c34;
  color: white;
  padding: 20px;
  border-radius: 8px;
  width: 80%;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}
